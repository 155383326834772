<template>
  <v-app class="grey lighten-4 ">
    <v-app-bar app class="grey darken-4 d-print-none">
      <v-row justify="center" align="center">
        <v-icon class="white--text" :large="$vuetify.breakpoint.smAndUp">mdi-clock-time-nine-outline</v-icon>

        <v-col class="text-left">
          <div class="white--text text-button  text-no-wrap" style="line-height: 1rem">
            Tablero Electoral
          </div>
          <div class="white--text text-caption text-uppercase ">
            Ultima actualización {{ lastUpdate }}
          </div>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.mdAndUp">
          <v-tabs centered background-color="grey darken-4">
            <v-tab :to="{ name: 'tableros' }" title="Tableros">
              <v-icon x-large>$vuetify.icons.tabladatos</v-icon>
            </v-tab>
            <v-tab :to="{ name: 'pronostico' }" title="Pronostico">
              <v-icon x-large color="white">mdi mdi-chart-box</v-icon>
            </v-tab>
            <!-- <v-tab :to="{ name: 'grafico' }">
              <v-icon x-large>$vuetify.icons.accesoespecialistas</v-icon>
            </v-tab> -->
            <v-tab :to="{ name: 'avance' }" title="Avance">
              <v-icon x-large>$vuetify.icons.fdtsol</v-icon>
            </v-tab>
            <v-tab :to="{ name: 'tendencia' }" title="Tendencias">
              <v-icon color="white" x-large>mdi mdi-chart-pie</v-icon>
            </v-tab>
            <v-tab :to="{ name: 'secciones' }" title="Resultados por Secciones">
              <v-icon color="white" x-large>mdi mdi-table</v-icon>
            </v-tab>
            <!-- <v-tab :to="{ name: 'camaras' }" title="Distribución de bancas">
              <v-icon color="white" x-large>mdi-account-group</v-icon>
            </v-tab> -->
            <v-tab v-if="rol == 'ADMIN'" :to="{ name: 'usuarios' }">
              <v-icon x-large>$vuetify.icons.config</v-icon>
            </v-tab>

          </v-tabs>
        </v-col>

        <v-col class="text-right white--text">
          <span> {{ user.username }} </span>
          <v-icon @click="logout" class="white--text">mdi-logout</v-icon>
        </v-col>
      </v-row>
    </v-app-bar>

    <router-view></router-view>

    <!-- <v-footer app class="light-blue">
      <span class="white--text text-caption"
        >Dirección de Innovación y Tecnología Electoral - DINE</span
      >

    </v-footer> -->
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "dashboard",
  computed: {
    ...mapState(["user", "lastUpdate", "rol"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("LOGOUT_REQUEST").then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style></style>